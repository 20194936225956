import Http from "./Http";

export default {
    async getCountry() {
        return await Http.get('general/country')
    },
    async getDashboardMessage(dashboard_version) {
       return await Http.get(`/general/dashboard/message?version=${dashboard_version}`);
    },
    async getHypePopUp(data) {
        return await Http.get(`/general/app/marketingPopup?${data}`)
    },
    async getAppParamsKeys() {
        return await Http.get(`/general/app/applicationParamsKeys`)
    },
    async getAppParams() {
        return await Http.get(`/general/app/applicationParams`)
    },
    async updateAppParams(data) {
        return await Http.post(`/general/app/updateAppParams`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
     }
        })
    },
    async deleteAppParamsImage(data){
        return await Http.post(`/general/app/deleteAppParamsImage` ,data);
    },
    async createHypePopUp(data) {
        return await Http.post(`/general/app/marketingPopup`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async updateHypePopUp(id, data) {
        return await Http.post(`/general/app/marketingPopup/${id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async addHypePopUpImage(data) {
        return await Http.post(`/general/app/marketingPopup/image`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async deleteHypePopUp(id) {
        return await Http.delete(`/general/app/marketingPopup/${id}`)
    },
    async deleteHypePopUpImage(id) {
        return await Http.delete(`/general/app/marketingPopup/image/${id}`)
    },
    async getactionTextOptions() {
        return await Http.get(`/general/app/marketingPopup/actionText`)
    },
    async getCrmOptIns(data=''){
        return await Http.get(`/general/optins?${data}`);
    },
    async createCrmOptIn(data){
        return await Http.post(`/general/createCrmOptIn`,data , {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    async deleteCrmOptIn(id){
        return await Http.post(`/general/deleteCrmOptIn/${id}`);
    },
    async editCrmOptIn(id,data){

        return await Http.post(`/general/editCrmOptIn/${id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async getReportCrmOptIns(data){

        return await Http.get(`/general/getReportCrmOptIns?${data}`)
    },

    async getDirContent(data){
        return await Http.get(`/general/dir?${data}`)
    },

    async getFile(data){
        return await Http.get(`/general/dir/file?${data}`)
    },

    async createDirectory(data){
        return await Http.post(`/general/dir`, data)
    },

    async uploadFile(data){
        return await Http.post(`/general/dir/upload`, data)
    },

    async deleteFile(data){
        return await Http.delete(`/general/dir/file?${data}`)
    },

    async deleteDirectory(data){
        return await Http.delete(`/general/dir?${data}`)
    },

    async getDashboardDefinitions(data){
        return await Http.get(`/general/dashboard/definitions?${data}`)
    },
    async getTermsAndConditions(){
        return await Http.get(`/general/getTermsAndConditions`)
    },
    async editTermsAndConditions(data) {
        return await Http.post(`/general/editTermsAndConditions`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async createTermsAndConditions(data) {
        return await Http.post(`/general/createTermsAndConditions`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async getAppCrmMimic(){
        return await Http.get(`/general/getAppCrmMimic`) ;
    },
    async createAppCrmMimic(data) {
        return await Http.post(`/general/createAppCrmMimic`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async deleteAppCrmMimic(id){
        return await Http.delete(`/general/deleteAppCrmMimic/${id}`)
    } ,
    async getDashboardHelp(data){
        return await Http.get(`/general/getDashboardHelp?${data}`)
    },
    async getSecurityGroupRules(data) {
        return await Http.get(`/general/security_groups/tenantrules`, { params: data })
    },
    async addTenantSecurityGroupRule(data) {
        return await Http.post(`/general/security_groups/addtenantrule`, data)
    },
    async removeTenantSecurityGroupRule(data) {
        return await Http.delete(`/general/security_groups/removetenantrule`, { params: data })
    },
    async getTenantVPNUsers() {
        return await Http.get(`/general/vpn/tenantusers`)
    },
    async addTenantVPNUsers(data) {
        return await Http.post(`/general/vpn/tenantusers`, data)
    },
    async removeTenantVPNUser(username) {
        return await Http.delete(`/general/vpn/tenantusers/${username}`)
    },

}
